import './App.css';
import Navbar from './Components/Navbar'
import About from './Components/About'
import Path from './Components/Path'
import Projects from './Components/Projects'
import Skills from './Components/Skills'
import Contact from './Components/Contact'

function App() {
  return (
    <div className="App p-2 md:p-16">
      <Navbar />
      <About />
      <Path />
      <Projects />
      <Skills />
      <Contact /> 
    </div>
  );
}

export default App;
