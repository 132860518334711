import React from "react";
import arkitokImage from '../public/images/arkitok.png';
import githubImage from '../public/images/github.png';

const Projects = () => {
    return (
        <section id="my-projects" className="container-blue">
            <h2 className="pt-5 text-2xl">MY PROJECTS</h2>
            <div className="grid grid-cols-1 md:grid-cols-2">
                <span className="m-8 p-8 border border-indigo-300 rounded-2xl">
                    <span className="flex justify-center items-center  max-h-94">
                        <img src={arkitokImage} className="rounded-lg" />
                    </span>
                    <div className="mt-8 text-left">
                        <h4 className="font-bold text-xl">ARKITOK</h4>
                        <p className="mt-2">
                            Arkitok is my side-project, is a web platform where architects and architectural fans can find their favourite projects, build their own digital library and connect it to their printed publications. I created it from scratch using Ruby on Rails.
                        </p>
                        <div className="mt-5">
                            <a href="https://arkitok.com" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Visit Arkitok!</a>
                        </div>
                    </div>
                </span>

                <span className="m-8 p-8 border border-indigo-300 rounded-2xl">
                    <span className="flex justify-center items-center  max-h-94 bg rounded">
                        <img src={githubImage} className="rounded-lg" />
                    </span>
                    <div className="mt-8 text-left">
                        <h4 className="font-bold text-xl">OPEN SOURCE PROJECTS</h4>
                        <p className="mt-2">
                            Here you will find some open source projects I have created and some projects that I built just to play around.
                        </p>
                        <div className="mt-5">
                            <a href="https://github.com/dantgn" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Visit my github profile</a>
                        </div>
                    </div>
                </span>
            </div>
        </section>
    )
}

export default Projects